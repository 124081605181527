import { Button } from "@tremor/react"
import { useAuth } from "App"
import { useCreateMyToken } from "Core/Authentication/API/MyTokenAPI"
import { InputSecure, InputText } from "Common/UI/Component/Input"
import { transformErrorNetwork, transformResponseError } from "Common/Utility/formatter"
import classNames from "classnames"
import { useState } from "react"
import { toast } from "react-hot-toast"
import { Link } from "react-router-dom"

export default function LoginBlock() {
    const auth = useAuth()
    const [remember, setRemember] = useState(true)
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const createMyToken = useCreateMyToken({
        role: 'Employee',
        onSuccess: ({ data: response }) => {
            if (response.code === 200) {
                auth.signIn(response.data.account, response.data.token)
            }
        },
        onError: (error) => {
            toast.error(transformErrorNetwork(error))
        }
    })
    const error = transformResponseError(createMyToken.error)

    const submit = (event) => {
        createMyToken.mutate({ id: email, password: password })
        event.preventDefault()
    }

    return (
        <form onSubmit={submit} className={classNames("space-y-6 mx-0 md:mx-3 mt-0 md:mt-7", auth.isLoadingAuth ? 'opacity-10' : '')}>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-6'>
                <div>
                    <label for="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your email</label>
                    <InputText
                        value={email}
                        onChange={({ target: { value } }) => setEmail(value)}
                        placeholder="your@email.com"
                        errorMessage={error['body.username']}
                        className="w-full"

                    />
                </div>

                <div>
                    <label for="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your password</label>
                    <InputSecure
                        value={password}
                        onChange={({ target: { value } }) => setPassword(value)}
                        placeholder="your password/passphrases"
                        errorMessage={error['body.password']}
                        className="w-full"
                    />
                </div>
            </div>
            <div className="flex items-start">
                <div className="flex items-start">
                    <div className="flex items-center h-5">
                        <input id="remember" type="checkbox" value="" className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800" />
                    </div>
                    <label for="remember" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Ingat saya</label>
                </div>
            </div>
            <Button type="submit" className="w-full" loading={createMyToken.isLoading} loadingText="Logging in ..." >
                Masuk
            </Button>
            <div className="text-sm font-medium text-gray-500 dark:text-gray-300 hidden">
                Belum terdaftar? <Link to="/register" className="text-blue-700 hover:underline dark:text-blue-500">Daftar Baru</Link>
            </div>
        </form>
    )
}