const { create } = require("zustand");

export const useRegisterStore = create((set) => ({
    email: '',
    password: '',
    passwordConfirmation: '',

    changeEmail: ({ target: { value } }) => set(() => ({ email: value })),
    changePassword: ({ target: { value } }) => set(() => ({ password: value })),
    changePasswordConfirmation: ({ target: { value } }) => set(() => ({ passwordConfirmation: value })),
}))