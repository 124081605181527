import { isFunction } from "Common/Utility/is";
import moment from "moment";
import { create } from "zustand";
import { persist, createJSONStorage } from 'zustand/middleware'

const initialCreateReportStore = {
    title: '',
    description: '',
    date: moment().format('YYYY-MM-DD'),
    time: moment().format('HH:mm:ss'),
    location: '',
    personName: '',
}

export const useCreateReportStore = create(
    persist(
        (set) => ({
            ...initialCreateReportStore,


            setTitle: value => set(() => ({ title: value })),
            setPersonName: value => set(() => ({ personName: value })),
            setDate: value => set(() => ({ date: value })),
            setTime: value => set(() => ({ time: value })),
            setDescription: value => set(() => ({ description: value })),
            setLocation: value => set(() => ({ location: value })),
            resetStore: () => set(initialCreateReportStore)
        }),
        {
            name: 'create-report',
            storage: createJSONStorage(() => sessionStorage),
        }
    )
)
export const useCreateReportFileStore = create(
    (set) => ({
        files: [],

        setFiles: value => set((state) => isFunction(value) ? ({files: value(state.files)}) : ({ files: value })),
        resetStore: () => set({ files: [] })
    }),
)