import Input from "Common/UI/Component/Input";
import { useRegisterStore } from "./RegisterStore";
import { shallow } from 'zustand/shallow'
import Button from "Common/UI/Component/Button";
import Logo from "Common/UI/Component/Logo";
import toast from "react-hot-toast"
import { useAuth } from "App";
import { transformErrorNetwork, transformResponseError } from "Common/Utility/formatter";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useCreateMyCredential } from "Core/Authentication/API/MyCredentialAPI";
import Icon from "Common/UI/Component/Icon";
import classNames from "classnames";
import AlertView from "Common/UI/Block/AlertView";

export default function RegisterRoute() {
  const [searchParams] = useSearchParams()
  const returnURL = searchParams.get('returnURL')
  const navigate = useNavigate()
  const auth = useAuth()
  const { email, password, passwordConfirmation } = useRegisterStore(state => ({ email: state.email, password: state.password, passwordConfirmation: state.passwordConfirmation }), shallow)
  const { changeEmail, changePassword, changePasswordConfirmation } = useRegisterStore(state => ({ changeEmail: state.changeEmail, changePassword: state.changePassword, changePasswordConfirmation: state.changePasswordConfirmation }))
  const createMyCredential = useCreateMyCredential({
    onSuccess: ({ data: response }) => {
      if (response.code === 200) {
        auth.signIn(response.data.account, response.data.token)
      }
    },
    onError: (error) => {
      toast.error(transformErrorNetwork(error))
    }
  })
  const error = transformResponseError(createMyCredential.error)

  const submit = (e) => {
    e.preventDefault()
    createMyCredential.mutate({ email, password, passwordConfirmation })
  }

  console.log('error', error)
  return (
    <>
      <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <Logo.DepartmentLogo className="mx-auto h-30 w-auto" />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Daftar ke dalam sistem</h2>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form onSubmit={submit} className="space-y-6" action="#" method="POST">
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                  Surel (Surat Elektronik)
                </label>
                <div className="mt-1">
                  <Input.InputText value={email} onChange={changeEmail} className={classNames("w-full", error['email'] ? 'p-invalid' : null)} />
                </div>
                <div className="mt-1.5">
                  <AlertView.ErrorInput errors={error['email']} />
                </div>
              </div>

              <div>
                <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                  Kata Sandi
                </label>
                <div className="mt-1">
                  <Input.InputSecure value={password} onChange={changePassword} className={classNames("w-full", error['email'] ? 'p-invalid' : null)} />
                </div>
                <div className="mt-1.5">
                  <AlertView.ErrorInput errors={error['password']} />
                </div>
              </div>
              <div>
                <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                  Kata Sandi Konfirmasi
                </label>
                <div className="mt-1">
                  <Input.InputSecure value={passwordConfirmation} onChange={changePasswordConfirmation} className={classNames("w-full", error['email'] ? 'p-invalid' : null)} />
                </div>
                <div className="mt-1.5">
                  <AlertView.ErrorInput errors={error['passwordConfirmation']} />
                </div>
              </div>


              <div className="flex  justify-between">
                <Link to="/login">
                  <Button.LoadableButton variant="secondary" type="button" icon={Icon.IconOutline.ArrowLeftCircleIcon} >
                    atau Masuk
                  </Button.LoadableButton>
                </Link>
                <Button.LoadableButton type="submit" loading={createMyCredential.isLoading} loadingText="Memproses Masuk.." >
                  Daftar
                </Button.LoadableButton>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}