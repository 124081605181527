import { Route, Routes } from "react-router-dom";
import PageRoutes from "Core/Content/UseCase/Route/Page/PageRoutes";
import MyTokenRoutes from "Core/Authentication/UseCase/Route/MyToken/MyTokenRoutes";
import ReportRoutes from "Core/Whistleblow/UseCase/Route/Report/ReportRoutes";
import MyCredentialRoutes from "Core/Authentication/UseCase/Route/MyCredential/MyCredentialRoutes";

export default function GuestRouters() {
    return (
        <Routes>
            <Route path="/" element={<PageRoutes.HomeRoute />} />
            <Route path="/login" element={<MyTokenRoutes.LoginRoute />} />
            <Route path="/register" element={<MyCredentialRoutes.RegisterRoute />} />

            <Route path="/submit">
                <Route index element={<ReportRoutes.SubmitReportRoute />} />
                <Route path="*" element={<ReportRoutes.SubmitReportRoute />} />
            </Route>
        </Routes>
    )
}