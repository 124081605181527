import { Card, Divider, Metric } from "@tremor/react";
import { useAuth } from "App";
import { useConfirmDialogStore } from "Common/UI/Block/ConfirmDialog";
import Button from "Common/UI/Component/Button";
import Icon from "Common/UI/Component/Icon";
import LoginBlock from "./LoginBlock";
import { Link } from "react-router-dom";

export default function SubmitReportInitialSubRoute() {
    const { user, signOut } = useAuth()
    const confirm = useConfirmDialogStore(state => state.confirm)

    return (
        <Card>
            <Metric>
                Whistleblowing System Direktorat Kemitraan dan Penyelarasan Dunia Usaha dan Dunia Industri
            </Metric>
            <article className='my-3 font-mono space-y-2'>
                <p className=''>
                    <span className='font-bold'>DISCLAIMER: </span>
                    Whistleblowing System Direktorat Kemitraan dan Penyelarasan Dunia Usaha dan Dunia Industri  (WBS-Direktorat Mitras DUDI)
                </p>

                <p>
                    WBS-Direktorat Mitras DUDI adalah sistem yang disediakan Direktorat Kemitraan dan Penyelarasan Dunia Usaha dan Dunia Industri bagi Anda yang perlu melaporkan dugaan terjadinya pelanggaran yang dilakukan oleh pihak internal termasuk Pegawai Pemerintah Non Pegawai Negeri (PPNPN) dan Outsourcing.
                </p>
                <p>
                    Dugaan pelanggaran yang dapat dilaporkan melalui WBS-Direktorat Mitras DUDI ini mencakup dugaan pelanggaran terhadap peraturan perundang-undangan dan peraturan intern Direktorat Kemitraan dan Penyelarasan Dunia Usaha dan Dunia Industri, yang meliputi :
                    <ol className='list-disc ml-11 space-y-2 my-2'>
                        <li>korupsi, kolusi dan nepotisme</li>
                        <li>pencurian, kecurangan (fraud)</li>
                        <li>suap, gratifikasi</li>
                        <li>pelanggaran kode etik dan pedoman perilaku satker</li>
                        <li>perbuatan melanggar hukum lainnya.</li>
                    </ol>
                    Laporan dapat disampaikan melalui sarana yang terdapat informasi kontak
                </p>
                <p>
                    Direktorat Kemitraan dan Penyelarasan Dunia Usaha dan Dunia Industri sangat menghargai informasi yang Anda laporkan dan berkomitmen menindaklanjutinya, oleh karenanya kerahasiaan identitas Anda sebagai whistleblower kami utamakan tetap terjaga.
                </p>
                <p>
                    KERAHASIAAN PELAPOR:
                    Direktorat Kemitraan dan Penyelarasan Dunia Usaha dan Dunia Industri melindungi kerahasiaan Pelapor, sesuai Peraturan Menteri Pendidikan dan Kebudayaan Nomor 126 Tahun 2014 tentang Penanganan Pengaduan di Lingkungan Kementerian Pendidikan dan Kebudayaan, pada pasal (3), yang mengatur bahwa:
                    <ol className='list-decimal ml-12 space-y-2 my-2'>
                        <li>Satuan Pengawasan Intern, unit kerja tertentu, dan/atau Inspektorat Jenderal wajib memberikan perlindungan kepada Pelapor Pengaduan.</li>
                        <li>Dalam memberikan perlindungan kepada pejabat, pegawai Aparatur Sipil Negara, dan masyarakat, Satuan Pengawasan Intern, unit kerja tertentu, dan/atau Inspektorat Jenderal dapat meminta bantuan kepada Lembaga Perlindungan Saksi dan Korban (LPSK).</li>
                        <li>Satuan Pengawasan Intern, unit kerja tertentu, dan/atau Inspektorat Jenderal dalam rangka memberikan perlindungan kepada Pengadu/Pelapor Pelanggaran, menjaga kerahasiaan identitas Pelapor Pengaduan</li>
                        <li>Satuan Pengawasan Intern dan unit kerja tertentu data mengungkapkan identitas Pelapor Pengaduan kepada Inspektorat Jenderal untuk keperluan penyidikan dan persidangan.</li>
                    </ol>
                </p>
            </article>

            <Divider />
            {user ?
                <div>
                    <div className='flex flex-1 justify-between'>
                        <div className="flex items-center space-x-5">
                            <div className="flex-shrink-0">
                                <div className="relative">
                                    <img
                                        className="h-16 w-16 rounded-full"
                                        src={user.avatarURL}
                                        alt=""
                                    />
                                    <span className="absolute inset-0 shadow-inner rounded-full" aria-hidden="true" />
                                </div>
                            </div>
                            <div>
                                <h1 className="text-2xl font-bold text-gray-900">
                                    {user.role?.employee?.roleEntity.name || user.email}
                                </h1>
                                <p className="text-sm font-medium text-gray-500">
                                    {user.email}
                                </p>
                            </div>
                        </div>
                        <div className='flex flex-col justify-center'>
                            <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
                                <Link to="/">
                                    <Button.LoadableButton variant="secondary" icon={Icon.IconSolid.ArrowLeftCircleIcon}>
                                        Batal
                                    </Button.LoadableButton>
                                </Link>
                                <Button.LoadableButton onClick={() => confirm({ onConfirm: () => signOut(), title: 'Keluar atau Ganti Pelapor', message: 'Dengan ini anda akan keluar dari sistem dan dapat masuk kembali sebagai akun yang berbeda. Apakah anda yakin dengan aksi ini?', icon: Icon.IconPhospor.SignOut })} icon={Icon.IconPhospor.SignOut} size="xl" color="indigo" variant="secondary">
                                    Keluar sebagai {user.email}

                                    <img
                                        className="inline-block h-6 w-6 ring-1 ml-3 shadow-lg rounded-full"
                                        src={user.avatarURL}
                                        alt=""
                                    />
                                </Button.LoadableButton>
                                <Link to="/submit/form">
                                    <Button.LoadableButton icon={Icon.IconPhospor.PencilSimple} size="xl" color="indigo" variant="primary">
                                        Lanjut Isi Laporan
                                    </Button.LoadableButton>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div> :
                <LoginBlock />
            }
        </Card>
    )
}