import { useAuth } from "App";
import MainLayout from "Common/UI/Layout/Main/MainLayout";

export default function ProfileRoute() {
    const { user } = useAuth()
    return (
        <MainLayout>
           
        </MainLayout>
    )
}