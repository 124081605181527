import MainLayout from "Common/UI/Layout/Main/MainLayout";
import { Button } from '@tremor/react'
import { useAuth } from 'App'
import { BookOpen } from '@phosphor-icons/react'
import moment from 'moment'
import { CompanyLogo } from 'Common/UI/Component/Logo'
import { Link } from "react-router-dom";
import Icon from "Common/UI/Component/Icon";
import { useConfirmDialogStore } from "Common/UI/Block/ConfirmDialog";

export default function HomeRoute() {
    const confirm = useConfirmDialogStore(state => state.confirm)
    const { user, signOut } = useAuth()

    return (
        <MainLayout>
            <main className="mt-16 mx-auto max-w-7xl px-4 sm:mt-24">
                <div className="text-center">
                    <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
                        <span className="block xl:inline">Selamat Datang di </span>{' '}
                        <span className="block text-indigo-600 xl:inline">Whistleblowing Systems (WBS)</span> {' '}
                    </h1>

                    <h1 className="text-lg tracking-tight font-extrabold text-gray-600 sm:text-2xl md:text-3xl">
                        <span className="block xl:inline"> Direktorat Kemitraan dan Penyelarasan Dunia Usaha dan Dunia Industri (DUDI), Direktorat Jendral Pendidikan Vokasi</span>
                    </h1>
                    <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-md md:mt-5 md:text-lg md:max-w-3xl">
                        Jika Anda melihat atau mengetahui dugaan penyimpangan perilaku/penyalahgunaan wewenang atau permasalahan lain yang dilakukan pegawai di lingkungan Direktorat Kemitraan dan Penyelarasan Dunia Usaha dan Dunia Industri (DUDI), Direktorat Jendral Pendidikan Vokasi.
                    </p>
                    <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-md md:mt-5 md:text-lg md:max-w-3xl">
                        Silahkan melapor ke WBS Direktorat Kemitraan dan Penyelarasan Dunia Usaha dan Dunia Industri (DUDI), Direktorat Jendral Pendidikan Vokasi Kemdikbudristek. Jika laporan anda memenuhi syarat/kriteria WBS, maka akan diproses lebih lanjut.
                    </p>
                    <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8 gap-3">
                        <Link to="/submit">
                            <Button icon={Icon.IconPhospor.Megaphone} size="xl" color="indigo">
                                Laporkan Sekarang!
                            </Button>
                        </Link>
                        {user ?
                            <Button onClick={() => confirm({ onConfirm: () => signOut(), title: 'Keluar atau Ganti Pelapor', message: 'Dengan ini anda akan keluar dari sistem dan dapat masuk kembali sebagai akun yang berbeda. Apakah anda yakin dengan aksi ini?', icon: Icon.IconPhospor.SignOut })} icon={Icon.IconPhospor.SignOut} size="xl" color="indigo" variant="secondary">
                                Keluar sebagai {user.email}

                                <img
                                    className="inline-block h-6 w-6 ring-1 ml-3 shadow-lg rounded-full"
                                    src={user.avatarURL}
                                    alt=""
                                />
                            </Button> :
                            <Link to="/login">
                                <Button size="xl" color="indigo" variant="secondary">
                                    Masuk

                                    <Icon.IconPhospor.UserCircle
                                        className="inline-block h-6 w-6 ring-1 ml-3 shadow-lg rounded-full"
                                    />
                                </Button>
                            </Link>
                        }
                    </div>
                </div>
            </main>
            <div className='flex flex-1 justify-center mt-10 pb-[100px]'>
                <div className='w-full max-w-7xl p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-6 md:p-8  pb-10'>
                    <div className="relative mt-3">
                        <div className="absolute inset-0 flex items-center" aria-hidden="true">
                            <div className="w-full border-t border-gray-300" />
                        </div>
                        <div className="relative flex justify-end">
                            <span className="pl-3 bg-white text-lg font-medium text-gray-900">
                                {moment().format('dddd, D MMM, YYYY')}
                            </span>
                        </div>
                    </div>
                    <article className='my-5 font-mono space-y-2'>
                        <div className='pt-4'>
                            <section className="py-12 bg-gray-50 overflow-hidden md:py-20 lg:py-24">
                                <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                                    <svg
                                        className="absolute top-full right-full transform translate-x-1/3 -translate-y-1/4 lg:translate-x-1/2 xl:-translate-y-1/2"
                                        width={404}
                                        height={404}
                                        fill="none"
                                        viewBox="0 0 404 404"
                                        role="img"
                                        aria-labelledby="svg-workcation"
                                    >
                                        <title id="svg-workcation">Workcation</title>
                                        <defs>
                                            <pattern
                                                id="ad119f34-7694-4c31-947f-5c9d249b21f3"
                                                x={0}
                                                y={0}
                                                width={20}
                                                height={20}
                                                patternUnits="userSpaceOnUse"
                                            >
                                                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                                            </pattern>
                                        </defs>
                                        <rect width={404} height={404} fill="url(#ad119f34-7694-4c31-947f-5c9d249b21f3)" />
                                    </svg>

                                    <div className="relative flex flex-col items-center">
                                        <CompanyLogo width={100} height={100} />
                                        <blockquote className="mt-10">
                                            <div className="max-w-3xl mx-auto text-center text-2xl leading-9 font-medium text-gray-900">
                                                <p>
                                                    &ldquo;Seseorang yang melaporkan perbuatan dugaan tindak pidana korupsi yang terjadi di dalam organisasi tempatnya bekerja, atau pihak terkait lainnya yang memiliki akses informasi yang memadai atas terjadinya dugaan tindak pidana korupsi tersebut.&rdquo;
                                                </p>
                                            </div>
                                            <footer className="mt-8">
                                                <div className="md:flex md:items-center md:justify-center">
                                                    <div className="md:flex-shrink-0">
                                                        <img
                                                            className="mx-auto h-10 w-10 rounded-full"
                                                            src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                                            alt=""
                                                        />
                                                    </div>
                                                    <div className="mt-3 text-center md:mt-0 md:ml-4 md:flex md:items-center">
                                                        <div className="text-base font-medium text-gray-900">Whistleblower</div>

                                                        <svg className="hidden md:block mx-1 h-5 w-5 text-indigo-600" fill="currentColor" viewBox="0 0 20 20">
                                                            <path d="M11 0h3L9 20H6l5-20z" />
                                                        </svg>

                                                        <div className="text-base font-medium text-gray-500">anonim</div>
                                                    </div>
                                                </div>
                                            </footer>
                                        </blockquote>
                                    </div>
                                </div>
                            </section>
                        </div>

                        <div className='pt-10'>
                            <h3 className='text-xl font-bold'>
                                Pengaduan Anda akan mudah ditindaklanjuti apabila memenuhi kriteria sebagai berikut:
                            </h3>
                            <div className="py-12 bg-white">
                                <div className="max-w-xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
                                    <h2 className="sr-only">A better way to send money.</h2>
                                    <dl className="space-y-10 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-8">
                                        <div>
                                            <dt>
                                                <div className="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                                                    <span>W</span>
                                                </div>
                                                <p className="mt-5 text-lg leading-6 font-medium text-gray-900">
                                                    What
                                                </p>
                                            </dt>
                                            <dd className="mt-2 text-base text-gray-500">
                                                Ada penyimpangan kasus yang dilaporkan/peristiwa yang terjadi
                                            </dd>
                                        </div>
                                        <div>
                                            <dt>
                                                <div className="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                                                    <span>W</span>
                                                </div>
                                                <p className="mt-5 text-lg leading-6 font-medium text-gray-900">
                                                    Where
                                                </p>
                                            </dt>
                                            <dd className="mt-2 text-base text-gray-500">
                                                Menjelaskan dimana
                                            </dd>
                                        </div>
                                        <div>
                                            <dt>
                                                <div className="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                                                    <span>W</span>
                                                </div>
                                                <p className="mt-5 text-lg leading-6 font-medium text-gray-900">
                                                    When
                                                </p>
                                            </dt>
                                            <dd className="mt-2 text-base text-gray-500">
                                                Kapan kasus tersebut dilakukan
                                            </dd>
                                        </div>
                                        <div>
                                            <dt>
                                                <div className="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                                                    <span>W</span>
                                                </div>
                                                <p className="mt-5 text-lg leading-6 font-medium text-gray-900">
                                                    Who
                                                </p>
                                            </dt>
                                            <dd className="mt-2 text-base text-gray-500">
                                                Siapa pejabat/pegawai yang melakukan atau terlibat
                                            </dd>
                                        </div>
                                        <div>
                                            <dt>
                                                <div className="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                                                    <span>W</span>
                                                </div>
                                                <p className="mt-5 text-lg leading-6 font-medium text-gray-900">
                                                    Why
                                                </p>
                                            </dt>
                                            <dd className="mt-2 text-base text-gray-500">
                                                Mengapa penyimpanan tersebut terjadi
                                            </dd>
                                        </div>
                                        <div>
                                            <dt>
                                                <div className="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                                                    <span>H</span>
                                                </div>
                                                <p className="mt-5 text-lg leading-6 font-medium text-gray-900">
                                                    How
                                                </p>
                                            </dt>
                                            <dd className="mt-2 text-base text-gray-500">
                                                Bagaimana cara perbuatan tersebut dilakukan
                                            </dd>
                                        </div>
                                    </dl>
                                </div>
                            </div>
                        </div>

                        <p>
                            Dilengkapi dengan bukti permulaan (data, dokumen, gambar dan rekaman) yang mendukung/menjelaskan adanya dugaan penyimpangan/penyalahgunaan wewewenang atau permasalahan lain.
                        </p>
                        <h3 className='text-xl font-bold'>
                            Kerahasiaan Pelapor
                        </h3>
                        <p>
                            Direktorat Kemitraan dan Penyelarasan Dunia Usaha dan Dunia Industri (DUDI), Direktorat Jendral Pendidikan Vokasi Kemdikbudristek akan merahasiakan identitas pelapor. Laporan dapat disampaikan dengan nama samaran/alias dan hindari orang lain mengetahui username dan password anda.
                        </p>
                    </article>

                    <div className='flex justify-end'>
                        <Link to="/submit">
                            <Button icon={Icon.IconPhospor.Megaphone} size="xl" color="indigo">
                                Laporkan Sekarang!
                            </Button>
                        </Link>
                    </div>
                </div>
            </div>
        </MainLayout>
    )
}