import Icon from "Common/UI/Component/Icon";
import classNames from "classnames";
import { NavLink } from "react-router-dom";

export default function StepItemLinkComponent({ to, strictTo = false, end, number, completed, title }) {
    return (
        <li className="relative md:flex-1 md:flex">
            <NavLink to={to} end={strictTo} className="group flex items-center">
                {({ isActive }) => (
                    <span className="px-6 py-4 flex items-center text-sm font-medium">
                        <span className={classNames("flex-shrink-0 w-10 h-10 flex items-center justify-center border-2  rounded-full ", completed ?(isActive ?  "bg-indigo-600 group-hover:bg-indigo-800" : "bg-indigo-400 group-hover:bg-indigo-600") : isActive ? "border-indigo-600" : "border-gray-300 group-hover:border-gray-400")}>
                            <span className={classNames(isActive ? "text-indigo-600" : "text-gray-500 group-hover:text-gray-900")}>
                                {completed ?
                                    <Icon.IconSolid.CheckIcon className="w-6 h-6 text-white" aria-hidden="true" /> :
                                    number
                                }
                            </span>
                        </span>
                        <span className="ml-4 text-sm font-medium text-gray-500 group-hover:text-gray-900">
                            {title}
                        </span>
                    </span>
                )}
            </NavLink>
            {end ? null :
                <div className="hidden md:block absolute top-0 right-0 h-full w-5" aria-hidden="true">
                    <svg
                        className="h-full w-full text-gray-300"
                        viewBox="0 0 22 80"
                        fill="none"
                        preserveAspectRatio="none"
                    >
                        <path
                            d="M0 -2L20 40L0 82"
                            vectorEffect="non-scaling-stroke"
                            stroke="currentcolor"
                            strokeLinejoin="round"
                        />
                    </svg>
                </div>
            }

        </li>
    )
}