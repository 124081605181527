import { Menu, Transition } from "@headlessui/react";
import { Card } from "@tremor/react";
import { useAuth } from "App";
import Button from "Common/UI/Component/Button";
import Icon from "Common/UI/Component/Icon";
import Input from "Common/UI/Component/Input";
import { useCreateReportFileStore, useCreateReportStore } from "Core/Whistleblow/Store/CreateReportStore";
import classNames from "classnames";
import { Fragment, useMemo } from "react";
import { FileUploader } from "react-drag-drop-files";
import { Link } from "react-router-dom";
import { shallow } from "zustand/shallow";
import { FileIcon, defaultStyles } from "react-file-icon"
import { humanizeRelativeFromDate } from "Common/Utility/humanize";
import moment from "moment";

export default function SubmitReportFormSubRoute() {
    const { user, signOut } = useAuth()

    const { personName, title, date, time, location, description } = useCreateReportStore(state => ({
        personName: state.personName, date: state.date, time: state.time,
        location: state.location, title: state.title, description: state.description,
    }), shallow)
    const files = useCreateReportFileStore(state => state.files)

    const { setPersonName, setTitle, setDate, setTime, setLocation, setDescription } = useCreateReportStore(state => ({
        setPersonName: state.setPersonName, setDate: state.setDate, setTime: state.setTime,
        setLocation: state.setLocation, setTitle: state.setTitle, setDescription: state.setDescription,
    }))
    const setFiles = useCreateReportFileStore(state => state.setFiles)

    const isFilledForm = useMemo(() => {
        return [title, personName, date, time, location, description].filter(item => item).length === 6 && files.length >= 1
    }, [title, personName, date, time, files, location, description])


    return (
        <Card>
            <div>

                <div className='grid grid-cols-1 md:grid-cols-3 gap-4'>
                    <div>
                        <label for="description" className="block mb-2 text-sm font-medium text-gray-900 ">
                            Nama yang dilaporkan
                        </label>
                        <Input.InputText
                            value={personName}
                            onChange={({ target: { value } }) => setPersonName(value)}
                            className='w-full'
                            placeholder="Siapa pejabat/pegawai yang melakukan atau terlibat"
                        />
                    </div>
                    <div>
                        <label for="description" className="block mb-2 text-sm font-medium text-gray-900 ">
                            Pelanggaran Yang Dilaporkan
                        </label>
                        <Input.InputText
                            value={title}
                            onChange={({ target: { value } }) => setTitle(value)}
                            className='w-full'
                            placeholder="Pelanggaran yang akan dilaporkan"
                        />
                    </div>
                </div>

                <div className='grid grid-cols-1 md:grid-cols-3 gap-4'>
                    <div>
                        <label for="description" className="block mb-2 text-sm font-medium text-gray-900 ">
                            Tanggal Kejadian
                        </label>
                        <Input.InputText
                            type='date'
                            value={date}
                            onChange={({ target: { value } }) => setDate(value)}
                            className='w-full'
                            placeholder="Date"
                        />
                    </div>
                    <div>
                        <label for="description" className="block mb-2 text-sm font-medium text-gray-900 ">
                            Jam Kejadian
                        </label>
                        <Input.InputText
                            type='time'
                            value={time}
                            onChange={({ target: { value } }) => setTime(value)}
                            className='w-full'
                            placeholder="Date"
                        />
                    </div>
                    <div>
                        <label for="description" className="block mb-2 text-sm font-medium text-gray-900 ">
                            Dimana Kejadian
                        </label>
                        <Input.InputText
                            value={location}
                            onChange={({ target: { value } }) => setLocation(value)}
                            className='w-full'
                            placeholder="Lokasi kejadian"
                        />
                    </div>
                </div>
                <div>
                    <label for="description" className="block mb-2 text-sm font-medium text-gray-900 ">
                        Kronologis Permasalahan
                    </label>
                    <Input.InputTextarea
                        value={description}
                        onChange={({ target: { value } }) => setDescription(value)}
                        className='w-full'
                        placeholder="Jelaskan secara 5W + 1H. apa penyimpangannya, kenapa terjadi dan bagaiman cara perbuatan tersebut dilakukan"
                    />
                </div>

                <div>
                    <label for="description" className="block mb-2 text-sm font-medium text-gray-900 ">
                        Berkas/Gambar Pendukung
                    </label>
                    <section className="mt-8 pb-16" aria-labelledby="gallery-heading">
                        <FileUploader
                            multiple={true}
                            handleChange={(files) => setFiles(Array.from(files))}
                            name="file"
                            types={['JPG', 'PNG', 'GIF', 'PDF', 'DOC', 'DOCX', 'XLS', 'XLSX', 'PPT', 'PPTX']}
                        >

                            <div className>
                                <div role="presentation" tabIndex={0} className="mt-10 flex flex-col justify-center items-center mx-10">
                                    <div className>
                                        <div className="max-w-lg flex justify-center md:px-36 px-2 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md ">
                                            <div className="space-y-1 text-center">
                                                <svg className="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                                                    <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                                <div className="flex text-sm text-gray-600">
                                                    <label htmlFor="file-upload" className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                                                        <span>Upload a file</span>
                                                        <input accept="image/jpeg,image/png,application/pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation" multiple type="file" tabIndex={-1} style={{ display: 'none' }} />
                                                    </label>
                                                    <p className="pl-1">or drag and drop</p>
                                                </div>
                                                <p className="text-xs text-gray-500">PNG, JPG, GIF up to 10MB</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </FileUploader>
                        <ul
                            role="list"
                            className="my-10 grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 md:grid-cols-4 lg:grid-cols-3 xl:grid-cols-5 xl:gap-x-8"
                        >
                            {files && files.map((file, fileIndex) => {
                                const extension = file.name.substr(file.name.lastIndexOf('.') + 1)

                                return (
                                    <li key={file.name} className={classNames("relative border p-5 rounded-lg shadow-sm hover:shadow-xl",)}>
                                        <div className="flex flex-row justify-between ">
                                            <div>

                                            </div>

                                            <Menu as="div" className="flex-shrink-0">
                                                <Menu.Button className=" bg-white inline-flex items-center justify-center text-gray-400 rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
                                                    <Icon.IconSolid.EllipsisVerticalIcon className='h-4 w-4' />
                                                </Menu.Button>
                                                <Transition
                                                    as={Fragment}
                                                    enter="transition ease-out duration-100"
                                                    enterFrom="transform opacity-0 scale-95"
                                                    enterTo="transform opacity-100 scale-100"
                                                    leave="transition ease-in duration-75"
                                                    leaveFrom="transform opacity-100 scale-100"
                                                    leaveTo="transform opacity-0 scale-95"
                                                >
                                                    <Menu.Items className="z-10 mx-3 origin-top-right absolute right-10 top-3 w-48 mt-1 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none">

                                                        <div className="py-1">

                                                            <Menu.Item>
                                                                {({ active }) => (
                                                                    <button
                                                                        onClick={() => {
                                                                            setFiles(previousFiles => {
                                                                                let update = [...previousFiles]
                                                                                update.splice(fileIndex, 1)
                                                                                return update
                                                                            })
                                                                        }}
                                                                        className={classNames(
                                                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                            'block px-4 py-2 text-sm'
                                                                        )}
                                                                    >
                                                                        Hapus
                                                                    </button>
                                                                )}
                                                            </Menu.Item>
                                                        </div>
                                                    </Menu.Items>
                                                </Transition>
                                            </Menu>
                                        </div>


                                        <div onClick={() => {
                                            if (['jpg', 'jpeg', 'png', 'bmp'].includes(extension)) {

                                            }
                                        }}
                                            className="flex flex-col gap-8 items-center justify-center cursor-pointer">
                                            <div className="w-16 h-16">
                                                <FileIcon
                                                    extension={extension}
                                                    {...defaultStyles[extension]}
                                                />
                                            </div>
                                            <h1 className="font-semibold flex w-full justify-center" style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                                                {file.name}
                                            </h1>
                                        </div>


                                        <div className="relative my-5">
                                            <div className="absolute inset-0 flex items-center" aria-hidden="true">
                                                <div className="w-full border-t border-gray-300" />
                                            </div>
                                            <div className="relative flex justify-between">
                                                <span className="bg-white px-2 text-gray-500">
                                                    <svg className="h-2 w-2 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                                        <path
                                                            fill="#6B7280"
                                                            fillRule="evenodd"
                                                            d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                                                            clipRule="evenodd"
                                                        />
                                                    </svg>
                                                </span>
                                                <span className="bg-white px-2 text-gray-500">
                                                    <svg className="h-2 w-2 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                                        <path
                                                            fill="#6B7280"
                                                            fillRule="evenodd"
                                                            d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                                                            clipRule="evenodd"
                                                        />
                                                    </svg>
                                                </span>
                                            </div>
                                        </div>

                                        <div className="flex flex-row justify-between">
                                            <dd>
                                                <dl className="font-semibold">File size:</dl>
                                                <dt className="text-gray-500 text-xs">{file.sizeReadable}</dt>
                                            </dd>
                                            <dd>
                                                <dl className="font-semibold text-right">Last modified:</dl>
                                                <dt className="text-gray-500 text-xs text-right">{humanizeRelativeFromDate(moment.unix(file.lastModified).format('YYYY-MM-DD HH:mm:ss'))}</dt>
                                            </dd>
                                        </div>
                                    </li>
                                )
                            })}
                        </ul>
                    </section>
                </div>

                <div className='flex flex-col justify-center'>
                    <div className="mt-6 flex justify-end gap-3">
                        <Link to="/submit">
                            <Button.LoadableButton
                                icon={Icon.IconSolid.ArrowLeftCircleIcon}
                                type="button"
                                variant='secondary'
                            >
                                Back
                            </Button.LoadableButton>
                        </Link>
                        <Link to={isFilledForm ? '/submit/preview' : null}>
                            <Button.LoadableButton
                                disabled={!isFilledForm}
                                type="submit"
                            >
                                Preview
                            </Button.LoadableButton>
                        </Link>
                    </div>
                </div>
            </div>
        </Card>
    )
}