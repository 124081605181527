import { requestGet, requestPost } from "Common/Provider/RestClient"
import { useMutation, useQuery } from "react-query"


export function useDetailMyCredential({ onSuccess, onError, enabled = true }) {
    const data = useQuery('/authentication/credential/my-credential', () => requestGet('/authentication/credential/my-credential'), {
        enabled: enabled,
        onSuccess: (response) => onSuccess(response),
        onError: (error) => onError ? onError(error) : null,
    })
    return data
}
export function useCreateMyCredential({ onSuccess, onError }) {
    const mutation = useMutation(({ email, password, passwordConfirmation }) => requestPost('/authentication/credential/my-credential', { email, password, passwordConfirmation }), {
        onSuccess: (response) => onSuccess ? onSuccess(response) : null,
        onError: (error) => onError ? onError(error) : null,
    })
    return mutation
}