import { Fragment, useState } from "react"
import Lottie from "lottie-react";
import Button from "Common/UI/Component/Button";
import { useCreateReportFileStore, useCreateReportStore } from "Core/Whistleblow/Store/CreateReportStore";
import Icon from "Common/UI/Component/Icon";
import { Card, Metric, Text, Title } from "@tremor/react";
import { shallow } from "zustand/shallow";
import { Divider } from "@tremor/react";
import classNames from "classnames";
import { FileIcon, defaultStyles } from "react-file-icon";
import { humanizeRelativeFromDate } from "Common/Utility/humanize";
import moment from "moment";
import { useCreateReport } from "Core/Whistleblow/API/ReportAPI";
import { transformResponseToSingle } from "Common/Utility/formatter";
import { Link, useNavigate } from "react-router-dom";

export default function SubmitReportPreviewSubRoute() {
    const navigate = useNavigate()
    const [reported, setReported] = useState(null)
    const resetStore = useCreateReportStore(state => state.resetStore)
    const resetFileStore = useCreateReportFileStore(state => state.resetStore)
    const files = useCreateReportFileStore(state => state.files)
    const { title, personName, date, time, location, description } = useCreateReportStore(
        (state) => ({ title: state.title, date: state.date, location: state.location, description: state.description, personName: state.personName, time: state.time }),
        shallow
    )

    const createReport = useCreateReport(({
        onSuccess: (response) => {
            const record = transformResponseToSingle(response)
            setReported(record)
            reset()
        }
    }))

    const reset = () => {
        resetFileStore()
        resetStore()
        navigate('/submit')
    }

    return (
        <div>
            {reported ?
                <div className='flex flex-1 items-center flex-col mt-20'>
                    <Lottie animationData={require('Asset/Lottie/submitted.json')} loop={true} style={{ width: 400 }} />
                    <Button.LoadableButton onClick={reset} className="mt-5" icon={Icon.IconPhospor.ArrowClockwise}>
                        Submit Again
                    </Button.LoadableButton>
                </div> :
                <article>
                    <Divider />
                    <main className="mx-2 mt-10">
                        <div className="grid grid-col-1 md:grid-cols-3  gap-6 mt-10">
                            <Card>
                                <Text>
                                    Nama Yang Dilaporkan
                                </Text>
                                <Metric>
                                    {personName}
                                </Metric>
                            </Card>
                            <Card>
                                <Text>
                                    Pelanggaran Yang Dilaporkan
                                </Text>
                                <Metric>
                                    {title}
                                </Metric>
                            </Card>
                        </div>
                        <div className="grid grid-col-1 md:grid-cols-3  gap-6 mt-10">
                            <Card>
                                <Text>
                                    Dimana Kejadian
                                </Text>
                                <Metric>
                                    {location}
                                </Metric>
                            </Card>
                            <Card>
                                <Text>
                                    Tanggal Kejadian
                                </Text>
                                <Metric>
                                    {date}
                                </Metric>
                            </Card>
                            <Card>
                                <Text>
                                    Jam Kejadian
                                </Text>
                                <Metric>
                                    {time}
                                </Metric>
                            </Card>
                        </div>
                        <div className="mt-6">
                            <Card>
                                <Text>
                                    Kronologis Permasalahan
                                </Text>
                                <Metric>
                                    {description}
                                </Metric>
                            </Card>
                        </div>

                        <div>
                            <section className="mt-8 pb-16" aria-labelledby="gallery-heading">

                                <ul
                                    role="list"
                                    className="my-10 grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 md:grid-cols-4 lg:grid-cols-3 xl:grid-cols-5 xl:gap-x-8"
                                >
                                    {files && files.map((file, fileIndex) => {
                                        const extension = file.name.substr(file.name.lastIndexOf('.') + 1)

                                        return (
                                            <li key={file.name} className={classNames("relative border p-5 rounded-lg shadow-sm hover:shadow-xl",)}>
                                                <div className="flex flex-row justify-between ">
                                                    <div>
                                                    </div>
                                                </div>


                                                <div onClick={() => {
                                                    if (['jpg', 'jpeg', 'png', 'bmp'].includes(extension)) {

                                                    }
                                                }}
                                                    className="flex flex-col gap-8 items-center justify-center cursor-pointer">
                                                    <div className="w-16 h-16">
                                                        <FileIcon
                                                            extension={extension}
                                                            {...defaultStyles[extension]}
                                                        />
                                                    </div>
                                                    <h1 className="font-semibold flex w-full justify-center" style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                                                        {file.name}
                                                    </h1>
                                                </div>


                                                <div className="relative my-5">
                                                    <div className="absolute inset-0 flex items-center" aria-hidden="true">
                                                        <div className="w-full border-t border-gray-300" />
                                                    </div>
                                                    <div className="relative flex justify-between">
                                                        <span className="bg-white px-2 text-gray-500">
                                                            <svg className="h-2 w-2 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                                                <path
                                                                    fill="#6B7280"
                                                                    fillRule="evenodd"
                                                                    d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                                                                    clipRule="evenodd"
                                                                />
                                                            </svg>
                                                        </span>
                                                        <span className="bg-white px-2 text-gray-500">
                                                            <svg className="h-2 w-2 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                                                <path
                                                                    fill="#6B7280"
                                                                    fillRule="evenodd"
                                                                    d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                                                                    clipRule="evenodd"
                                                                />
                                                            </svg>
                                                        </span>
                                                    </div>
                                                </div>

                                                <div className="flex flex-row justify-between">
                                                    <dd>
                                                        <dl className="font-semibold">File size:</dl>
                                                        <dt className="text-gray-500 text-xs">{file.sizeReadable}</dt>
                                                    </dd>
                                                    <dd>
                                                        <dl className="font-semibold text-right">Last modified:</dl>
                                                        <dt className="text-gray-500 text-xs text-right">{humanizeRelativeFromDate(moment.unix(file.lastModified).format('YYYY-MM-DD HH:mm:ss'))}</dt>
                                                    </dd>
                                                </div>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </section>
                        </div>
                    </main>



                    <div className='flex flex-col justify-center'>
                        <div className="mt-6 flex justify-end gap-3">
                            <Link to="/submit/form">
                                <Button.LoadableButton
                                    type="button"
                                    variant='secondary'
                                >
                                    Ubah lagi
                                </Button.LoadableButton>
                            </Link>
                            <Button.LoadableButton
                                loading={createReport.isLoading}
                                loadingText="Mengirimkan Laporan..."
                                icon={Icon.IconPhospor.PaperPlaneTilt}
                                onClick={() => createReport.mutate({ personName, title, description, location, date, time, files })}
                                type="button"
                            >
                                Kirim
                            </Button.LoadableButton>
                        </div>
                    </div>
                </article>
            }
        </div>
    )
}