import { Route, Routes, Navigate, useSearchParams } from "react-router-dom";
import PageRoutes from "Core/Content/UseCase/Route/Page/PageRoutes";
import ReportRoutes from "Core/Whistleblow/UseCase/Route/Report/ReportRoutes";

export default function AuthenticatedRouters() {
    return (
        <Routes>
            <Route path="/" element={<PageRoutes.HomeRoute />} />
            <Route path="/login" element={<Redirect />} />
            <Route path="/register" element={<Redirect />} />

            <Route path="/submit">
                <Route index element={<ReportRoutes.SubmitReportRoute />} />
                <Route path="*" element={<ReportRoutes.SubmitReportRoute />} />
            </Route>
        </Routes>
    )
}

const Redirect = () => {
    const [searchParams] = useSearchParams()
    const returnURL = searchParams.get('returnURL')
    return <Navigate to={returnURL ? returnURL : '/'} />
}