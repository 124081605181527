import { useAuth } from 'App';
import { useCreateReportFileStore, useCreateReportStore } from 'Core/Whistleblow/Store/CreateReportStore';
import { useMemo, } from 'react';
import { shallow } from 'zustand/shallow';
import MainLayout from 'Common/UI/Layout/Main/MainLayout';
import { Navigate, Route, Routes } from 'react-router-dom';
import StepItemLinkComponent from './StepItemLinkComponent';
import SubmitReportInitialSubRoute from './SubmitReportInitialSubRoute';
import SubmitReportFormSubRoute from './SubmitReportFormSubRoute';
import SubmitReportPreviewSubRoute from './SubmitReportPreviewSubRoute';

export default function SubmitReportRoute() {
    const { user } = useAuth()
    const files = useCreateReportFileStore(state => state.files)
    const { title, personName, date, time, location, description } = useCreateReportStore(
        (state) => ({ title: state.title, date: state.date, location: state.location, description: state.description, personName: state.personName, time: state.time }),
        shallow
    )

    const isFilledForm = useMemo(() => {
        return [title, personName, date, time, location, description].filter(item => item).length === 6 && files.length >= 1
    }, [title, personName, date, time, files, location, description])

    return (
        <MainLayout>
            <div className='flex flex-col flex-1 items-center mt-48 gap-2'>
                <div className="w-full max-w-7xl p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-6 md:p-8 ">
                    <form className="space-y-6" action="#">
                        <h5 className="text-xl font-medium text-gray-900 ">Submit your Report</h5>

                        <nav aria-label="Progress">
                            <ol role="list" className="border border-gray-300 rounded-md divide-y divide-gray-300 md:flex md:divide-y-0">
                                <StepItemLinkComponent
                                    to="/submit"
                                    number="01"
                                    title="Permulaan"
                                    strictTo={true}
                                    completed={user !== null}
                                />
                                <StepItemLinkComponent
                                    to="/submit/form"
                                    number="02"
                                    title="Isi Form"
                                    completed={isFilledForm}
                                />
                                <StepItemLinkComponent
                                    to="/submit/preview"
                                    number="03"
                                    title="Pratinjau"
                                    end
                                />
                            </ol>
                        </nav>


                    </form>
                </div>
                <div className="w-full max-w-7xl">

                    {user ?
                        <Routes>
                            <Route path="/" element={<SubmitReportInitialSubRoute />} />
                            <Route path="/form" element={<SubmitReportFormSubRoute />} />
                            <Route path="/preview" element={<SubmitReportPreviewSubRoute />} />
                        </Routes> :
                        <Routes>
                            <Route path="/" element={<SubmitReportInitialSubRoute />} />
                            <Route path="/form" element={<Navigate to={`/login?returnURL=/submit/form`} />} />
                            <Route path="/preview" element={<Navigate to="/login?returnURL=/submit/form" />} />
                        </Routes>
                    }
                </div>
            </div>
        </MainLayout>
    )
} 